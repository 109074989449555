/* Existing Styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.slash {
  color: #aaa;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.info {
  color: #d4f6ff;
  background-color: rgb(102, 102, 102);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Card-color .card-body {
  background-color: #fff7f7;
}

.Card-text {
  font-family: "Forum", serif;
}

#Matrix {
  position: fixed;
  z-index: -1;
}

iframe,
embed {
  border-radius: var(--bs-card-border-radius);
  width: 100%;
  background-color: #aaa;
  margin-bottom: 0.75rem;
}

.card-img {
  margin-bottom: 0.75rem;
}

body {
  background-color: black !important;
}

@media (prefers-reduced-motion) {
  canvas {
    display: none;
  }
}

.e,
.n,
.d,
.a {
  font-size: 400px;
  font-family: "Monomaniac One", monospace;
  transform: rotate(90deg);
  transform-origin: 50% 82%;
  animation: rotating 10s linear infinite, fade-in 2.5s ease-in forwards,
    rainbow 60s linear infinite;
  position: absolute;
  top: calc(-185px);
  left: calc(310px);
  opacity: 0;
  color: #ff6600;
}

.fade-in {
  opacity: 0;
  animation: fade-in 0.33s ease-in forwards;
  & + .fade-in {
    animation-delay: 0.1s;
    & + .fade-in {
      animation-delay: 0.2s;
    }
  }
}

.n {
  animation-delay: 2.5s;
}

.d {
  animation-delay: 5s;
}

.a {
  animation-delay: 7.5s;
}

.mini-name {
  .e {
    animation-delay: 2.5s;
  }

  .n {
    animation-delay: 5s;
  }

  .d {
    animation-delay: 7.5s;
  }

  .a {
    animation-delay: 10s;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rotating {
  0% {
    transform: translate(0px, 0px) rotate(90deg);
  }

  12.5% {
    transform: translate(0, 0) rotate(0deg);
  }

  25% {
    transform: translate(-90px, 0) rotate(0deg);
  }

  37.5% {
    transform: translate(-90px, 0) rotate(-90deg);
  }

  50% {
    transform: translate(-90px, 90px) rotate(-90deg);
  }

  62.5% {
    transform: translate(-90px, 90px) rotate(-180deg);
  }

  75% {
    transform: translate(0px, 90px) rotate(-180deg);
  }

  87.5% {
    transform: translate(0px, 90px) rotate(-270deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(-270deg);
  }
}

@keyframes rainbow {
  0% {
    color: #ff6600;
  }

  33% {
    color: #00ff66;
  }

  66% {
    color: #6600ff;
  }
}

/* Additions */


.section-header {
  text-align: center;
  font-family: "Georgia", serif;
  margin-bottom: 20px;
}

.section-subheader {
  margin-top: 30px;
  margin-bottom: 15px;
  font-family: "Verdana", sans-serif;
}

.media-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.media-item {
  max-width: 400px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.media-separator {
  margin: 30px 0;
  height: 2px;
  background-color: #ddd;
}

.custom-bullet-points {
  margin: 15px;
  padding-left: 20px;
  text-align: left;
}

/* New spacing class to add vertical space */
.spacing {
  height: 20px; /* Adjust height for the amount of space needed */
}
